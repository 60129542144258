import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CardContainer,
  CardContent,
  CardImage,
  DateRange,
  ImageContainer,
  Logo,
  LogoAndTitle,
  ProgressBar,
  ProgressBarContainer,
  ProgressText,
  StatusText,
  Title,
  XPText,
  KohortLabel,
  SoloLabel,
} from './ContribuitonCard.style';
import { PATH } from 'src/constants/path';
import { API_BASE_URL } from 'src/utils/utils';
import defaultDealIcon from 'src/assets/deal/MYX.png';
import defaultBannerImg from 'src/assets/deal/DELEGATE_banner.png';

interface ContributionCardProps {
  title: string;
  xp: number;
  imageUrl: string;
  logoUrl: string;
  startDate: string;
  endDate: string;
  progress: number;
  maxProgress: number;
  desc: JSX.Element;
  type?: string;
  id: number; // 각 카드의 고유 ID
  pjtId: number;
  kohortLabel: string;
}

const ContributionCard: React.FC<ContributionCardProps> = ({
  title,
  xp,
  imageUrl,
  logoUrl,
  startDate,
  endDate,
  progress,
  maxProgress,
  desc,
  type,
  id,
  pjtId,
  kohortLabel,
}) => {
  const navigate = useNavigate();
  console.log(desc);

  const handleCardClick = () => {
    navigate(`${PATH.CONTRIBUTION_DETAIL.replace(':contributionId', id.toString())}`, {
      state: {
        title,
        xp,
        imageUrl,
        logoUrl,
        startDate,
        endDate,
        progress,
        maxProgress,
        desc,
        type,
        id,
        pjtId,
        kohortLabel,
      },
    });
  };

  console.log(type);
  console.log('asd', kohortLabel);
  return (
    <CardContainer onClick={handleCardClick}>
      {/* <ImageContainer>
        <CardImage src={imageUrl ? `${API_BASE_URL}/${imageUrl}` : defaultBannerImg} alt={title} />
      </ImageContainer> */}
      <CardContent>
        {/* {type && <StatusText>{type}</StatusText>} */}
        <div style={{ marginBottom: '15px', marginLeft: '5px' }}>
          {kohortLabel === 'Kohort' ? (
            <KohortLabel>Kohort only</KohortLabel>
          ) : kohortLabel === 'Solo' ? (
            <SoloLabel aria-hidden="true" />
          ) : null}
        </div>

        <LogoAndTitle>
          <Logo src={logoUrl ? `${API_BASE_URL}/${logoUrl}` : defaultDealIcon} alt={`${title} Logo`} />

          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', gap: '10px' }}>
            <Title>{title}</Title>
            <XPText>{xp} XP</XPText>
          </div>
        </LogoAndTitle>

        <DateRange>
          {startDate} ~ {endDate}
        </DateRange>
        {type !== 'Daily-check' && type !== 'Rate-project' && type !== 'Invite' && type !== 'Feedback' && (
          <>
            <ProgressBarContainer>
              <ProgressBar $progress={progress} $maxProgress={maxProgress} />
            </ProgressBarContainer>
            <ProgressText>
              {progress} / {maxProgress}
            </ProgressText>
          </>
        )}
      </CardContent>
    </CardContainer>
  );
};

export default ContributionCard;
