import styled from 'styled-components';

export const WalletDiv = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 60px;
  margin-top: 54px;

  cursor: pointer;
`;
