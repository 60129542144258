import React, { useState, useRef, useEffect } from 'react';
import {
  WalletContainer,
  WalletButton,
  WalletIcon,
  WalletAddress,
  Address,
  DropdownItem,
  DropdownMenu,
  ProfileImage,
  UserInfo,
  UserName,
  IconWrapper,
} from './WalletComponent.styls';
import { images } from 'src/assets/dashboard/images';
import FaWallet from 'src/assets/main/wallet_latest.png';
import ProfileIcon from 'src/assets/main/account_circle.svg';
import LogoutIcon from 'src/assets/main/logout.svg';
import { API_BASE_URL } from 'src/utils/utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'src/store/user/UserSlice';
import { persistor } from 'src/store/store';

// Wallet 컴포넌트의 Props 정의
interface WalletProps {
  address: string; // 사용자의 지갑 주소
  profileImage?: string; // 사용자의 프로필 이미지
  username: string; // 사용자의 이름
  expertise: string;
}

// 지갑 주소를 축약된 형식으로 보여주기
const shortenAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const Wallet: React.FC<WalletProps> = ({ address, profileImage, username, expertise }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
    persistor.purge();
    localStorage.removeItem('persist:root');
    navigate('/');
  };

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleProfileClick = () => {
    navigate('/main/dashboard/profile');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false); // 드롭다운이 열린 상태에서 바깥을 클릭하면 닫기
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <WalletContainer ref={dropdownRef}>
      <WalletButton onClick={toggleDropdown}>
        <WalletIcon src={FaWallet} />
        <Address>{shortenAddress(address)}</Address>
      </WalletButton>

      {/* 드롭다운 메뉴 */}
      {isDropdownOpen && (
        <DropdownMenu>
          <DropdownItem>
            <ProfileImage
              src={profileImage ? `${API_BASE_URL}/${profileImage}` : images.profileDefaultIcon}
              alt="Profile"
            />
            <UserInfo onClick={handleProfileClick}>
              <UserName>{username}</UserName>
              <WalletAddress>{expertise}</WalletAddress>
            </UserInfo>
          </DropdownItem>
          <DropdownItem onClick={handleLogout}>
            <IconWrapper>
              <img src={LogoutIcon} alt="Logout Icon" />
            </IconWrapper>
            Sign Out
          </DropdownItem>
        </DropdownMenu>
      )}
    </WalletContainer>
  );
};

export default Wallet;
