import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainContainer,
  LeftSection,
  RightSection,
  ProjectTitleWrapper,
  ProjectTitle,
  ProjectLogo,
  KohortLabel,
  XPInfoWrapper,
  Dates,
  ProgressContainer,
  ProgressBar,
  ProgressText,
  BannerWrapper,
  BannerImage,
  Wrap,
} from './ContributionMain.style';
import { PATH } from 'src/constants/path';
import { API_BASE_URL } from 'src/utils/utils';
import defaultDealIcon from 'src/assets/deal/MYX.png';
import defaultBannerImg from 'src/assets/deal/DELEGATE_banner.png';
import { hover } from '@testing-library/user-event/dist/hover';

// ContributionMain 컴포넌트가 받을 props의 타입을 정의합니다.
interface ContributionMainProps {
  logoUrl: string;
  title: string;
  kohortLabel: string;
  totalAvg?: string;
  xp: string;
  startDate: string;
  endDate: string;
  progress: number;
  progressText: string;
  desc: JSX.Element;
  imageUrl: string;
  id: number;
  maxProgress: number;
  type: string;
  pjtId: number;
}

const ContributionMain: React.FC<ContributionMainProps> = ({
  logoUrl,
  title,
  kohortLabel,
  totalAvg,
  xp,
  startDate,
  endDate,
  progress,
  progressText,
  desc,
  imageUrl,
  id,
  maxProgress,
  type,
  pjtId,
}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`${PATH.CONTRIBUTION_DETAIL.replace(':contributionId', id.toString())}`, {
      state: {
        title,
        xp,
        imageUrl,
        logoUrl,
        startDate,
        endDate,
        desc,
        progress,
        maxProgress,
        type,
        id,
        pjtId,
      },
    });
  };

  console.log('asd', type);
  return (
    <Wrap onClick={handleCardClick}>
      <ProjectTitleWrapper>
        <ProjectLogo src={logoUrl ? `${API_BASE_URL}/${logoUrl}` : defaultDealIcon} alt="Project Logo" />
        <ProjectTitle>{title}</ProjectTitle>
      </ProjectTitleWrapper>
      <MainContainer>
        <LeftSection>
          <XPInfoWrapper>
            {kohortLabel === 'Kohort' && <KohortLabel>{kohortLabel}</KohortLabel>}
            <div style={{ marginLeft: 'auto' }}>
              {kohortLabel === 'Solo' ? (
                <>
                  {/* Solo일 때 */}
                  <span className="total-avg">Points </span>
                  <span className="xp-value">{xp} XP</span>
                </>
              ) : (
                <>
                  {/* Kohort일 때 */}
                  <span className="total-avg">Total Avg </span>
                  <span className="xp-value">{totalAvg ? totalAvg : xp}</span>
                </>
              )}
            </div>
          </XPInfoWrapper>
          <Dates>
            {startDate} ~ {endDate}
          </Dates>
          {!['Daily-check', 'Rate-project', 'Invite', 'Feedback'].includes(type) && (
            <ProgressContainer>
              <ProgressBar $progress={progress} $maxProgress={maxProgress} />
            </ProgressContainer>
          )}
        </LeftSection>
        <RightSection>
          <BannerWrapper>
            <BannerImage src={imageUrl ? `${API_BASE_URL}/${imageUrl}` : defaultBannerImg} alt="Banner" />
          </BannerWrapper>
        </RightSection>
      </MainContainer>
    </Wrap>
  );
};

export default ContributionMain;
