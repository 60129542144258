import styled from 'styled-components';

export const RateCheckWrapper = styled.div`
  /* align-items: center; */
  height: 100px;
  padding: 10px;
  background-color: white;

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #404040;
    margin-top: 10px;
  }

  @media (max-width: 1400px) {
    h3 {
      font-size: 18px;
    }
  }
`;

interface RateCheckButtonProps {
  isDisabled: boolean;
}

export const RateCheckButton = styled.button<RateCheckButtonProps>`
  color: ${(props) => (props.isDisabled ? '#6A5FEB' : '#fff')};
  background-color: ${(props) => (props.isDisabled ? '#FFF' : '#6a5feb')};
  padding: 10px 32px;
  border: ${(props) => (props.isDisabled ? '2px solid var(--Purple-900, #6A5FEB)' : 'none')};
  /* height: 45px; */
  border-radius: 20px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  font-size: 18px;
  font-weight: 600;
  align-items: end;
  float: right;

  &:hover {
    background-color: ${(props) => (props.isDisabled ? '#FFF' : '#564dba')};
  }

  @media (max-width: 1400px) {
    font-size: 16px;
    padding: 10px 20px;
  }
`;

export const RateIcon = styled.img`
  width: 50px;
  height: 50px;

  @media (max-width: 1400px) {
    width: 45px;
    height: 45px;
  }
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  width: 600px;
  height: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

export const CloseButton = styled.button`
  background: none;
  float: right;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 25px;
  margin-top: 30px;
  margin-left: 20px;
  text-align: start;
  color: #404040;
  font-weight: 600;
`;
export const SubText = styled.p`
  margin-top: 30px;
  margin-left: 20px;
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  color: #404040;
`;

export const ModalButton = styled.button`
  padding: 10px 24px;
  font-weight: 500;
  font-size: 18px;
  float: right;
  color: #fff;
  background-color: #6a5feb;
  cursor: pointer;
  border-radius: 20px;
  margin-top: 80px;
`;
