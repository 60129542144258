import React, { useState, useEffect, forwardRef } from 'react';
import {
  Container,
  Title,
  Form,
  Input,
  TextArea,
  ButtonContainer,
  CancelButton,
  SaveButton,
  ImageContainer,
  ProfileImage,
  HiddenFileInput,
  PreviewImage,
  EditIconBackground,
  EditIconContainer,
  EditIconForeground,
  BannerImage,
  TeamGroup,
  ContributionName,
  MaxParticipant,
  CategorySelect,
  TypeSelect,
  ContXp,
  DateInput,
  DateInputWrapper,
  CalendarIcon,
  Dates,
  DateWrapper,
  DeleteMissionButton,
  AddMembersButton,
  ButtonWrap,
  ContMission,
} from './EditContribution.style';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import DatePicker from 'react-datepicker';
import calenderIcon from 'src/assets/admin/calendar_month.svg';

interface Contribution {
  cont_id: number;
  pjt_name: string;
  cont_type: string;
  cont_category: string;
  cont_summary: string;
  cont_desc: string;
  cont_reward: number;
  start_date: string;
  end_date: string;
  cur_participant: number;
  max_participant: number;
  cont_xp: number;
  cont_status: string;
  cont_logo?: string;
  cont_banner?: string;
  missions?: Mission[]; // missions 배열 추가
}

interface EditContributionProps {
  selectedContributions: Contribution[];
  onCancel: () => void;
  onSave: (updatedContributions: Contribution[]) => void;
}
interface Mission {
  missionType: string;
  missionUrl: string;
}

const CustomDateInput = forwardRef(({ value, onClick, placeholder }: any, ref: any) => (
  <DateInputWrapper onClick={onClick} ref={ref}>
    <DateInput value={value} placeholder={placeholder} readOnly />
    <CalendarIcon src={calenderIcon} alt="calendar" />
  </DateInputWrapper>
));

const EditContribution: React.FC<EditContributionProps> = ({ selectedContributions, onCancel, onSave }) => {
  const [editedContributions, setEditedContributions] = useState(selectedContributions);

  const [profileImagePreview, setProfileImagePreview] = useState<string | null>(
    editedContributions[0].cont_logo ? `${API_BASE_URL}/${editedContributions[0].cont_logo}` : null
  );
  const [bannerImagePreview, setBannerImagePreview] = useState<string | null>(
    editedContributions[0].cont_banner ? `${API_BASE_URL}/${editedContributions[0].cont_banner}` : null
  );
  console.log(selectedContributions);

  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [bannerImage, setBannerImage] = useState<File | null>(null);
  const [maxParticipant, setMaxParticipant] = useState(selectedContributions[0].max_participant || '');
  const [category, setCategory] = useState(editedContributions[0].cont_category || '');
  const [type, setType] = useState(editedContributions[0].cont_type || '');
  const [xp, setXp] = useState(editedContributions[0].cont_xp || '');
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const startDateString = editedContributions[0]?.start_date;
    return startDateString ? new Date(startDateString) : null;
  });

  const [endDate, setEndDate] = useState<Date | null>(() => {
    const endDateString = editedContributions[0]?.end_date;
    return endDateString ? new Date(endDateString) : null;
  });
  const [desc, setDesc] = useState(editedContributions[0].cont_desc || '');
  const [missions, setMissions] = useState<Mission[]>([{ missionType: '', missionUrl: '' }]); // 초기 Mission 상태
  const [missionType, setMissionType] = useState('Mission');
  //   console.log(editedContributions[0]!.missions[0].missionType);

  const handleInputChange = (index: number, field: keyof Contribution, value: string | number) => {
    const updatedContributions = [...editedContributions];
    updatedContributions[index] = { ...updatedContributions[index], [field]: value };
    setEditedContributions(updatedContributions);
  };

  //   const handleSave = async () => {
  //     try {
  //       const response = await axios.put(`${API_BASE_URL}/api/admin/update-contributions`, editedContributions);
  //       onSave(response.data);
  //       onCancel();
  //     } catch (error) {
  //       console.error('Error updating contributions', error);
  //     }
  //   };

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setProfileImage(e.target.files[0]);
      setProfileImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleBannerImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setBannerImage(e.target.files[0]);
      setBannerImagePreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  // 미션 삭제 함수 (가장 마지막 미션 삭제)
  const deleteLastMission = () => {
    if (missions.length > 1) {
      setMissions(missions.slice(0, -1)); // 마지막 미션 제거
    }
  };

  // 미션 추가 함수
  const addMission = () => {
    setMissions([...missions, { missionType: '', missionUrl: '' }]);
  };

  const checkMission = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/get-contribution-ms`, {
        cont_id: editedContributions[0].cont_id,
      });

      console.log(response.data);
      setMissionType(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkMission();
  }, []);

  const handleSave = async () => {
    try {
      const formData = new FormData();

      if (profileImage) {
        formData.append('profileImage', profileImage);
      }

      if (bannerImage) {
        formData.append('bannerImage', bannerImage);
      }
      formData.append('cont_id', String(selectedContributions[0].cont_id));
      formData.append('pjt_name', selectedContributions[0].pjt_name);
      formData.append('max_participant', String(maxParticipant));
      formData.append('cont_category', category);
      formData.append('cont_type', type);
      formData.append('cont_xp', String(xp));
      formData.append('start_date', startDate ? startDate.toISOString() : '');
      formData.append('end_date', endDate ? endDate.toISOString() : '');
      formData.append('cont_desc', desc);
      // 미션 데이터 추가 (missions 배열을 JSON 문자열로 변환하여 추가)
      formData.append('missions', JSON.stringify(missions));

      // 백엔드에 수정된 데이터 전송
      // 서버에 데이터 전송
      const response = await axios.put(
        `${API_BASE_URL}/api/admin/update-contributions/${selectedContributions[0].cont_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(response.data);

      onSave(Array.isArray(response.data) ? response.data : [response.data]);

      console.log('Contribution updated successfully');
      onCancel(); // 모달 닫기
    } catch (error) {
      console.error('Error updating contribution', error);
    }
  };

  return (
    <Container>
      <ImageContainer>
        <ProfileImage as="label">
          {profileImagePreview && <PreviewImage src={profileImagePreview} alt="Profile Preview" />}
          <HiddenFileInput type="file" accept="image/*" onChange={handleProfileImageChange} />
          <EditIconContainer>
            <EditIconBackground>
              <EditIconForeground />
            </EditIconBackground>
          </EditIconContainer>
        </ProfileImage>
        <BannerImage as="label">
          {bannerImagePreview && <PreviewImage src={bannerImagePreview} alt="Banner Preview" />}
          <HiddenFileInput type="file" accept="image/*" onChange={handleBannerImageChange} />
          <EditIconContainer>
            <EditIconBackground>
              <EditIconForeground />
            </EditIconBackground>
          </EditIconContainer>
        </BannerImage>
      </ImageContainer>
      <Form>
        {editedContributions.map((contribution, index) => (
          <div key={contribution.cont_id}>
            <TeamGroup>
              {/* 고정된 Deal Name 표시 */}
              {/* <p>{selectedProjectName || 'No project selected'}</p> */}
              <ContributionName>{contribution.pjt_name || 'No project selected'}</ContributionName>
              <Input type="number" value={maxParticipant} onChange={(e) => setMaxParticipant(e.target.value)} />
              {/* <Select
            value={selectedProjectId || ''}
            onChange={(e) => {
              const project = projects.find((p) => p.pjt_id === Number(e.target.value));
              console.log(project);
              if (project) {
                setSelectedProjectId(project.pjt_id);
                setSelectedProjectName(project.pjt_name); // 프로젝트 이름 저장
                console.log('Selected Project Name:', project.pjt_name);
              }
            }}
          >
            <option value="" disabled>
              Deal Name
            </option>
            {projects.map((project) => (
              <option key={project.pjt_id} value={project.pjt_id}>
                {project.pjt_name}
              </option>
            ))}
          </Select> */}
            </TeamGroup>
            <TeamGroup>
              <CategorySelect value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value="" disabled>
                  Cont. Category
                </option>
                <option value="Solo">Solo</option>
                <option value="Kohort">Kohort</option>
              </CategorySelect>

              <TypeSelect value={type} onChange={(e) => setType(e.target.value)}>
                <option value="" disabled>
                  Cont. Type
                </option>
                <option value="Community Engagement">Community Engagement</option>
                <option value="Node/Validator">Node/Validator</option>
                <option value="Research">Research</option>
                <option value="Marketing">Marketing</option>
                <option value="Invite">Invite</option>
                <option value="Daily-check">Daily-check</option>
                <option value="Rate-project">Rate-project</option>
              </TypeSelect>
            </TeamGroup>
            <TeamGroup>
              <ContXp value={xp} onChange={(e) => setXp(e.target.value)}>
                <option value="" disabled>
                  Cont. XP
                </option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
              </ContXp>
              <DateWrapper>
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Due Date"
                  customInput={<CustomDateInput />}
                />
              </DateWrapper>
              <DateWrapper>
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date | null) => setEndDate(date)}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Due Date"
                  customInput={<CustomDateInput />}
                />
              </DateWrapper>
            </TeamGroup>
            <TeamGroup>
              <TextArea placeholder="Description" value={desc} onChange={(e) => setDesc(e.target.value)} />
            </TeamGroup>

            <ContMission value={missionType} onChange={(e) => setMissionType(e.target.value)}>
              <option value="" disabled>
                Mission Type
              </option>
              <option value="Twitter Follow">Twitter Follow</option>
              <option value="Twitter Retweet">Twitter Retweet</option>
              <option value="Discord Verify">Discord Verify</option>
              <option value="Telegram Join">Telegram Join</option>
              <option value="Invite People">Invite People</option>
              <option value="Daily Check">Daily Check</option>
              <option value="Rate Project">Rate Project</option>
            </ContMission>
            {/* 
            <ButtonWrap>
              {missions.length > 1 && (
                <DeleteMissionButton type="button" onClick={deleteLastMission}>
                  - Delete
                </DeleteMissionButton>
              )}
              <AddMembersButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation(); // 이벤트 전파 중단
                  addMission();
                }}
              >
                Add
              </AddMembersButton>
            </ButtonWrap> */}

            {/* 필요에 따라 다른 필드 추가 */}
          </div>
        ))}
      </Form>
      <ButtonContainer>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <SaveButton onClick={handleSave}>Edit</SaveButton>
      </ButtonContainer>
    </Container>
  );
};

export default EditContribution;
