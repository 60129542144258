import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';

// 스타일 설정
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 1000px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
`;

const ModalHeader = styled.div`
  float: right;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
  width: 100%;
`;

const InfoTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 3px solid #e3d9ff;
    color: #404040;
  }

  th {
    text-align: left;
    font-weight: bold;
    border-bottom: 3px solid #e3d9ff;
  }

  td {
    font-size: 16px;
  }
`;

const ViewButton = styled.button`
  background: none;
  border: none;
  color: #404040;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  padding: 6px 12px;
  font-size: 16px;
  color: #fff;
  background-color: #875cff;
  border: none;
  border-radius: 20px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #6a5feb;
  }
`;

const Input = styled.input`
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 8px;
`;

const ActivatedText = styled.span`
  color: #404040;
  font-weight: 500;
`;

const ViewText = styled.span`
  text-decoration: underline;
`;

const DownArrowIcon = styled.span`
  margin-left: 5px;
  font-size: 12px;
`;
interface FeedbackDetails {
  user_name: string;
  email_addr: string;
  admin_activate: string;
}

interface FeedBacklistProps {
  isOpen: boolean;
  onClose: () => void;
  feedback?: FeedbackDetails[]; // 여러 명의 초대자 정보를 받음
  id: number;
}

const FeedBackModal: React.FC<FeedBacklistProps> = ({ isOpen, onClose, feedback, id }) => {
  const [xpValues, setXpValues] = useState<{ [key: number]: number }>({});
  const [activatedStatus, setActivatedStatus] = useState<{ [key: number]: boolean }>({});

  console.log(id);
  console.log(feedback);

  useEffect(() => {
    // feedback의 admin_activate 값에 따라 activatedStatus 초기 설정
    const initialStatus =
      feedback?.reduce((status, user, index) => {
        status[index] = user.admin_activate === 'Y';
        return status;
      }, {} as { [key: number]: boolean }) || {};

    setActivatedStatus(initialStatus);
  }, [feedback]);

  // XP 값을 상태에 저장하는 함수
  const handleXPChange = (index: number, value: number) => {
    setXpValues({ ...xpValues, [index]: value });
  };

  // XP를 백엔드로 전송하는 함수
  const handleSendXP = async (user: FeedbackDetails, xp: number, index: number) => {
    console.log(user.user_name, xp);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/create-feedback`, {
        user_name: user.user_name,
        email_addr: user.email_addr,
        xp,
        cont_id: id,
      });
      if (response.status === 200 || response.status === 201) {
        alert('XP successfully sent!');
        setActivatedStatus((prevStatus) => ({
          ...prevStatus,
          [index]: true,
        }));
      } else {
        alert('Failed to send XP.');
      }
    } catch (error) {
      console.error('Error sending XP:', error);
      alert('An error occurred while sending XP.');
    }
  };

  //   const checkParticipate = async () => {
  //     try {
  //       const response = await axios.get(`${API_BASE_URL}/api/admin/get-missionMember`);

  //       console.log(response.data);
  //     } catch (error) {
  //       console.error('Error fetching participation data:', error);
  //     }
  //   };

  //   useEffect(() => {
  //     checkParticipate();
  //   }, []);

  if (!isOpen) return null;
  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        {feedback ? (
          <ModalBody>
            <InfoTable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>XP Input</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {feedback.map((user, index) => (
                  <tr key={index}>
                    <>
                      <td>{user.user_name}</td>
                      <td>{user.email_addr}</td>
                      <td>
                        <Input
                          type="number"
                          value={xpValues[index] || ''}
                          onChange={(e) => handleXPChange(index, Number(e.target.value))}
                          placeholder="Enter XP"
                        />
                      </td>
                      <td>
                        {activatedStatus[index] ? (
                          <ActivatedText>Activated</ActivatedText>
                        ) : (
                          <ActionButton onClick={() => handleSendXP(user, xpValues[index] || 0, index)}>
                            Active
                          </ActionButton>
                        )}
                      </td>
                    </>
                  </tr>
                ))}
              </tbody>
            </InfoTable>
          </ModalBody>
        ) : (
          <p>Loading...</p>
        )}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default FeedBackModal;
