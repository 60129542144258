import { FeedBackWrapper, FeedBackIcon, SubmitButton } from './FeedBackSetion.style';
import FBIcon from 'src/assets/contribution/emoji_objects.svg';

interface RateProjectProps {
  title?: string;
  xp?: number;
  progress?: number;
  maxProgress?: number;
  type?: string;
  desc?: string;
  id?: string;
  pjtId?: string;
  onRateConfirmChange?: (rateConfirm: string) => void; // 부모에서 받은 props
  isReloaded?: boolean; // 부모로부터 받은 상태
  disabled?: boolean;
}

const FeedBackSection: React.FC<RateProjectProps> = ({}) => {
  const handleButtonClick = () => {
    window.open('https://forms.gle/ycSpXRrUWqwkE5Kw7', '_blank');
  };

  return (
    <FeedBackWrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',

          gap: '15px',
        }}
      >
        <FeedBackIcon src={FBIcon} />
        <h3>Idea Submission</h3>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end', gap: '15px', alignItems: 'center' }}>
        <SubmitButton onClick={handleButtonClick}>Submit</SubmitButton>
      </div>
    </FeedBackWrapper>
  );
};

export default FeedBackSection;
