import styled from 'styled-components';

export const CardContainer = styled.div`
  border-radius: 20px;
  border: 5px solid #eeedfd;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 230px;
  /* margin: 10px; */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* 트랜지션 추가 */
  padding: 0px 10px 10px 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2ff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }

  @media (min-width: 1600px) {
    width: 500px;
  }

  @media (min-width: 2000px) {
    width: 500px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 130px; /* 이미지 영역의 높이를 140px에서 130px로 줄임 */
  overflow: hidden;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px; /* 패딩을 20px에서 15px로 줄임 */
  object-fit: contain;
  margin-top: 5px;
  border-radius: 20px;
  overflow: hidden; /* 필요한 경우 부모 요소에도 설정 */
`;

export const CardContent = styled.div`
  padding: 12px; /* 전체 패딩을 16px에서 12px로 줄임 */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 6px; /* 간격을 줄임 */
`;

export const Logo = styled.img`
  width: 80px; /* 로고 크기를 약간 줄임 */
  height: 80px;
`;

export const Title = styled.h3`
  font-size: 18px; /* 글자 크기를 약간 줄임 */
  font-weight: 500;
  color: #404040;
`;

export const StatusText = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #875cff;
  margin-bottom: 6px; /* 간격을 줄임 */
`;

export const XPText = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #404040;
`;

export const DateRange = styled.span`
  font-size: 16px;
  width: 100%;
  color: #404040;
  margin-bottom: 20px; /* 간격을 줄임 */
  text-align: right;
  font-weight: 500;
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px; /* 높이를 줄임 */
  background-color: #f7f5ff;
  border-radius: 10px;
  margin-bottom: 8px;
  position: relative;
`;

export const ProgressBar = styled.div<{ $progress: number; $maxProgress: number }>`
  width: ${({ $progress, $maxProgress }) => ($progress / $maxProgress) * 100}%;
  height: 100%;
  background-color: #6a5feb;
  border-radius: 5px;
`;

export const ProgressText = styled.span`
  font-size: 16px;
  color: #404040;
  text-align: right;
  width: 100%;
  font-weight: 500;
  display: block;
`;

export const KohortLabel = styled.div`
  color: #6a5feb;
  font-size: 18px;
  font-weight: 600;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SoloLabel = styled.div`
  visibility: hidden; // 요소를 보이지 않게 설정
  height: 18px; // 공간을 차지할 높이 설정
  width: 100px; // 필요에 따라 너비 설정
`;
