import {
  UserMemberContainer,
  Title,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  CheckboxHeader,
  Checkbox,
  Popup,
  CheckboxContainer,
  Checkmark,
  ConfirmBtn,
  DetailBtn,
} from './adminContribution.style';
import React, { useState, useEffect } from 'react';
import headerbox from 'src/assets/admin/headerbox.svg';
import checkbox from 'src/assets/admin/cellbox.svg';
import TopBar from 'src/components/admin/topbar/Topbar';
import checkmark from 'src/assets/admin/cell_check.svg';
import Modal from 'src/components/admin/modal/Modal';
import axios from 'axios';
import AddContribution from 'src/components/admin/modal/addContribution/AddContribution';
import InviteDetailsModal from 'src/components/admin/modal/inviteDetailModal/InviteDetailModal';
import { API_BASE_URL } from 'src/utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditContribution from 'src/components/admin/modal/editContribution/EditContribution';
import FeedBackModal from 'src/components/admin/modal/feedbackModal/feedbackModal';

interface Contribution {
  cont_id: number;
  pjt_name: string;
  cont_type: string;
  cont_category: string;
  cont_summary: string;
  cont_desc: string;
  cont_reward: number;
  start_date: string; // 'YYYY-MM-DD' 형식으로 가정
  end_date: string; // 'YYYY-MM-DD' 형식으로 가정
  cur_participant: number;
  max_participant: number;
  cont_xp: number;
  cont_status: string;
  cont_logo?: string;
  cont_banner?: string;
}

const AdminContribution = () => {
  const [contributions, setContributions] = useState<Contribution[]>([]);
  const [filteredContributions, setFilteredContributions] = useState<Contribution[]>([]);
  const [popupContent, setPopupContent] = useState<string | null>(null);
  const [popupPosition, setPopupPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  // 모달
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [inviteDetails, setInviteDetails] = useState([]);
  const [inviteeList, setInviteeList] = useState([]); // 초대받은 유저들의 리스트
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [isFeedOpen, setIsFeedOpen] = useState<boolean>(false);
  const [isContId, setIsContId] = useState<number>(0);

  // 검색
  const [searchTerm, setSearchTerm] = useState<string>('');

  // 체크박스
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  // 수정할 항목들
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false); // 수정 모달 상태
  const [selectedContributions, setSelectedContributions] = useState<Contribution[]>([]); // 선택된 데이터 상태

  // TopBar edit 버튼 클릭 시, 선택된 항목들을 모달에 전달
  const handleEditClick = () => {
    const selectedData = filteredContributions.filter((contribution) => selectedRows.has(contribution.cont_id));
    setSelectedContributions(selectedData);
    setIsEditModalOpen(true);
  };

  const fetchContributions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/admin/contribution-list`);
      const data = response.data;
      console.log(data);
      if (data.length < 20) {
        const emptyItems = Array.from({ length: 20 - data.length }, (_, index) => ({
          cont_id: `empty-${index}`, // 고유한 ID를 제공하기 위해 'empty' 접두사 추가
          pjt_name: '~~',
          cont_type: '~~',
          cont_summary: '~~',
          cont_desc: '~~',
          cont_reward: 0,
          start_date: '~~',
          end_date: '~~',
          cont_xp: 0,
          cont_status: '~~',
        }));
        setContributions([...data, ...emptyItems]);
        setFilteredContributions([...data, ...emptyItems]);
      } else {
        setContributions(data);
        setFilteredContributions(data);
      }
    } catch (error) {
      console.error('Error fetching contributions:', error);
    }
  };

  useEffect(() => {
    // 백엔드에서 Contribution 데이터를 가져오기

    fetchContributions();
  }, []);

  useEffect(() => {
    // Filter contributions based on the search term
    const filtered = contributions.filter((contribution) =>
      Object.values(contribution).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredContributions(filtered);
  }, [searchTerm, contributions]);

  // Handle individual checkbox change
  const handleCheckboxChange = (cont_id: number) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(cont_id)) {
        newSelectedRows.delete(cont_id);
      } else {
        newSelectedRows.add(cont_id);
      }
      return newSelectedRows;
    });
  };

  // Handle select all change
  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedRows(new Set());
    } else {
      const allIds = new Set(filteredContributions.map((contribution) => contribution.cont_id));
      setSelectedRows(allIds);
    }
    setIsAllSelected(!isAllSelected);
  };

  // Handle confirm button click
  const handleConfirmClick = async (cont_id: number) => {
    try {
      // 백엔드로 상태 업데이트 요청
      const response = await axios.put(`${API_BASE_URL}/api/admin/contribution-status`, {
        cont_id,
        cont_status: 'APPLIED',
      });

      if (response.status === 200) {
        // 상태가 성공적으로 변경된 경우
        toast.success(`Contribution ${cont_id} has been successfully applied!`, {
          position: 'top-right',
          autoClose: 1000,
        });

        // 상태 업데이트: 변경된 상태를 바로 반영
        setFilteredContributions((prevContributions) =>
          prevContributions.map((contribution) =>
            contribution.cont_id === cont_id ? { ...contribution, cont_status: 'APPLIED' } : contribution
          )
        );
      } else {
        toast.error('Failed to update contribution status.', {
          position: 'top-right',
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error updating contribution status:', error);
      toast.error('Error updating contribution status.', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  };

  const handleDetailClick = async (cont_id: number) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/admin/invite-details/${cont_id}`);
      console.log(response.data);

      if (response.data) {
        setInviteDetails(response.data); // 서버에서 받은 전체 데이터를 그대로 설정
        setIsDetailOpen(true); // 모달 열기
      } else {
        console.error('No data received from the server');
      }
    } catch (error) {
      console.error('Failed to fetch invite details', error);
    }
  };

  const handleFeedbackClick = async (cont_id: number) => {
    setIsContId(cont_id);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/admin/feedback-list`);
      console.log(response.data);
      if (response.data) {
        setFeedbackList(response.data);
        setIsFeedOpen(true);
      } else {
        console.error('No data received from the server');
      }
    } catch (error) {
      console.error('Failed to fetch feedback list', error);
    }
  };

  const onSaveEdit = async (updatedContributions: Contribution[]) => {
    await fetchContributions();
    // 수정된 항목을 반영하여 contributions 상태를 업데이트
    // const updatedData = contributions.map(
    //   (contribution) => updatedContributions.find((updated) => updated.cont_id === contribution.cont_id) || contribution
    // );
    // setContributions(updatedData);
    // setFilteredContributions(updatedData); // 검색 필터된 데이터에도 반영
    // setIsEditModalOpen(false); // Close modal
    setFilteredContributions(updatedContributions); // 검색 필터된 데이터에 반영
    setIsEditModalOpen(false); // 모달 닫기
  };

  // handleDeleteClick 함수 추가
  const handleDeleteClick = async () => {
    if (selectedRows.size === 0) {
      toast.warn('No items selected for deletion.');
      return;
    }

    try {
      // 선택된 항목들의 cont_id를 배열로 변환
      const contIdsToDelete = Array.from(selectedRows);
      console.log(contIdsToDelete);

      // 백엔드에 삭제 요청
      const response = await axios.post(`${API_BASE_URL}/api/admin/contribution-delete`, {
        cont_id: contIdsToDelete, // cont_id 배열을 직접 전달합니다.
      });
      if (response.status === 200) {
        // 성공 시 삭제된 항목을 프론트엔드에서도 제거
        toast.success('Selected contributions deleted successfully!');
        setContributions((prevContributions) =>
          prevContributions.filter((contribution) => !selectedRows.has(contribution.cont_id))
        );
        setFilteredContributions((prevFilteredContributions) =>
          prevFilteredContributions.filter((contribution) => !selectedRows.has(contribution.cont_id))
        );

        // 선택 해제
        setSelectedRows(new Set());
      } else {
        toast.error('Failed to delete selected contributions.');
      }
    } catch (error) {
      console.error('Error deleting contributions:', error);
      toast.error('Error deleting selected contributions.');
    }
  };

  return (
    <UserMemberContainer>
      <Title>Contribution Mgmt</Title>
      <TopBar
        onSearchChange={setSearchTerm}
        onEditClick={handleEditClick}
        onAddClick={() => setIsAddModalOpen(true)}
        onDeleteClick={handleDeleteClick}
      />
      <Table>
        <thead>
          <TableRow>
            <TableHeader>
              <CheckboxContainer onClick={handleSelectAllChange}>
                <Checkbox src={headerbox} alt="checkbox" />
                {isAllSelected && <Checkmark src={checkmark} alt="checkmark" />}
              </CheckboxContainer>
            </TableHeader>
            <TableHeader>Cont. Name</TableHeader>
            <TableHeader>Cont. Category</TableHeader>
            <TableHeader>Cont. Type</TableHeader>
            <TableHeader>Start date</TableHeader>
            <TableHeader>End date</TableHeader>
            <TableHeader>Max Part.</TableHeader>
            <TableHeader>Cur Part.</TableHeader>
            <TableHeader>XP</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Performance(%)</TableHeader>
            <TableHeader>Personal XP</TableHeader>
            <TableHeader>Confirm</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {filteredContributions.map((contribution) => (
            <TableRow key={contribution.cont_id} $isSelected={selectedRows.has(contribution.cont_id)}>
              <TableCell
                $isSelected={selectedRows.has(contribution.cont_id)}
                onClick={() => handleCheckboxChange(contribution.cont_id)}
              >
                <CheckboxContainer>
                  <Checkbox src={checkbox} alt="checkbox" />
                  {selectedRows.has(contribution.cont_id) && <Checkmark src={checkmark} alt="checkmark" />}
                </CheckboxContainer>
              </TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.pjt_name}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.cont_category}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.cont_type}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.start_date}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.end_date}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.max_participant}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.cur_participant}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.cont_xp}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{contribution.cont_status}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{'~~'}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>{'~~'}</TableCell>
              <TableCell $isSelected={selectedRows.has(contribution.cont_id)}>
                {/* Confirm 버튼 */}
                {contribution.cont_status === 'PENDING' && (
                  <ConfirmBtn onClick={() => handleConfirmClick(contribution.cont_id)}>Confirm</ConfirmBtn>
                )}

                {/* Detail 버튼 */}
                {contribution.cont_type === 'Invite' && contribution.cont_status === 'APPLIED' && (
                  <DetailBtn onClick={() => handleDetailClick(contribution.cont_id)}>Detail</DetailBtn>
                )}

                {contribution.cont_type === 'Feedback' && contribution.cont_status === 'APPLIED' && (
                  <DetailBtn onClick={() => handleFeedbackClick(contribution.cont_id)}>Detail</DetailBtn>
                )}

                {/* 상태 표시 */}
                {contribution.cont_status !== 'PENDING' && contribution.cont_status !== 'APPLIED' && (
                  <span>{contribution.cont_status}</span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>

      {popupContent && <Popup style={{ top: popupPosition.top, left: popupPosition.left }}>{popupContent}</Popup>}
      {/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="Edit Contribution">

        </Modal> */}

      <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} title="New Contribution">
        <AddContribution onCancel={() => setIsAddModalOpen(false)} />
      </Modal>
      <InviteDetailsModal
        isOpen={isDetailOpen}
        onClose={() => setIsDetailOpen(false)}
        inviteDetails={inviteDetails}
        // inviteeList={inviteeList}
      />
      <FeedBackModal
        isOpen={isFeedOpen}
        onClose={() => setIsFeedOpen(false)}
        feedback={feedbackList}
        id={isContId}
        // inviteeList={inviteeList}
      />
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} title="Edit Contributions">
        <EditContribution
          selectedContributions={selectedContributions}
          onCancel={() => setIsEditModalOpen(false)}
          onSave={onSaveEdit}
        />
      </Modal>
    </UserMemberContainer>
  );
};

export default AdminContribution;
