import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from 'react-slick';
import 'swiper/swiper-bundle.css';
import ContributionMain from 'src/components/dashboard/contribution/ContributionMain';
import ContributionCard from 'src/components/dashboard/contribution/ContributionCard'; // ContributionCard 임포트
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/components/dashboard/contribution/CustomSlider.css'; // 스타일 파일
import dayjs from 'dayjs'; // 날짜 비교를 위한 dayjs 사용
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import axios from 'axios';
import { API_BASE_URL } from 'src/utils/utils';
import Wallet from 'src/components/walletbtn/WalletComponent';
import { Pagination, Autoplay } from 'swiper/modules'; // 필요한 모듈 임포트
import RedirectModal from 'src/components/admin/modal/common/RedirectModal';
import ContributionModal from 'src/components/admin/modal/redirectMotal/Contribution';

dayjs.extend(customParseFormat);

const ContributionContainer = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  /* position: relative; */
  /* max-width: 1920px; */
  /* width: 100%; */
  width: 1000px;

  @media (min-width: 1600px) {
    margin-top: 10px;
    margin-left: 255px;
    margin-right: 255px;
    /* margin-bottom: 70px; */
  }

  @media (max-width: 1590px) {
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (min-width: 2000px) {
    margin: 0 auto;
    width: 1500px;
  }
`;

const ContributionHeader = styled.div`
  display: flex;
  justify-content: space-between; /* 양 끝으로 배치 */
  align-items: center; /* 수직 중앙 정렬 */
  /* margin-bottom: px; */
  margin-right: 60px;
  margin-top: 30px;
`;

const WalletWrap = styled.div`
  /* margin-right: 60px; */
`;

const ContributionTitle = styled.h1`
  color: #404040;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const ContributionTabs = styled.div`
  display: flex;
  justify-content: flex-start; // 왼쪽 정렬
  align-items: center;
  gap: 20px;
  width: 210px; // 탭이 컨테이너의 전체 너비를 차지하도록 설정
  border-bottom: 2px solid #d9d9d9;
  margin-top: 5px;
  /* padding-left: 5px; */
`;

const TabButton = styled.button<{ $active: boolean }>`
  font-size: 18px;
  font-weight: ${({ $active }) => ($active ? 'bold' : 'normal')};
  color: ${({ $active }) => ($active ? '#875CFF' : '#000')};
  border: none;
  background: none;
  cursor: pointer;

  /* border-bottom: ${({ $active }) => ($active ? '2px solid #D9D9D9' : 'none')}; */
  padding: 5px 10px;

  &:hover {
    color: #875cff;
  }
`;

const ContributionContent = styled.div`
  /* max-width: 1920px; */
  width: 1000px;
  height: 100%;
  padding-bottom: 30px;
  /* min-width: 1080px; */
  margin: 0 auto;
  @media (min-width: 2000px) {
    margin: 0 auto;
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 20px;
  margin-top: 20px;
  justify-items: center; /* 가로 방향 가운데 정렬 */
  /* width: 1050px; */
  /* max-width: 1920px; */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Contribution: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<'Ongoing' | 'Finished'>('Ongoing');
  const [userData, setUserData] = useState<any>(null);
  const [contributionData, setContributionData] = useState<any[]>([]); // 기여 데이터 상태 추가
  const user = useSelector((state: RootState) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 관리
  console.log(user);

  const handleCloseModal = () => {
    setIsModalOpen(false); // 모달 닫기
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.wallet_addr) {
        // user와 wallet_addr이 있는지 확인
        try {
          const response = await axios.get(`${API_BASE_URL}/api/user/profile/${user.wallet_addr}`);
          setUserData(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUserData({
            profileImage: 'default-profile.png',
            name: 'Default User',
            expertise: 'Unknown',
            points: 0,
            xp: 0,
            stats: {
              deal: 0,
              discover: 0,
              contribution: 0,
              governance: 0,
            },
          });
        }
      }
    };

    if (!userData) {
      fetchUserData();
    }
  }, [user, userData]); // user 값이 변경될 때마다 useEffect 실행
  useEffect(() => {
    // 백엔드 API를 통해 기여 데이터를 가져옴
    const fetchContributionData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/contribution/get-contribution`);
        setContributionData(response.data); // 가져온 데이터를 상태에 저장
      } catch (error) {
        console.error('Error fetching contribution data:', error);
      }
    };

    fetchContributionData();
  }, []); // 컴포넌트가 마운트될 때 데이터를 가져옴

  // 페이지가 렌더링될 때 모달이 자동으로 열리도록 설정
  useEffect(() => {
    setIsModalOpen(true); // 페이지 진입 시 모달 열기
  }, []);

  if (!user || !userData) {
    return <div>Loading...</div>; // user 또는 userData가 없을 때 로딩 처리
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const currentDate = dayjs();

  // 날짜 파싱 및 카드 필터링
  const ongoingCards = contributionData.filter((slide) => {
    if (!slide.start_date || !slide.end_date) return false; // 유효성 검사
    const endDate = dayjs(slide.end_date); // 종료 날짜 파싱
    return endDate.isAfter(currentDate); // 현재 날짜와 비교하여 Ongoing 필터링
  });

  const finishedCards = contributionData.filter((slide) => {
    if (!slide.start_date || !slide.end_date) return false; // 유효성 검사
    const endDate = dayjs(slide.end_date); // 종료 날짜 파싱
    return endDate.isBefore(currentDate); // 현재 날짜와 비교하여 Finished 필터링
  });

  console.log(contributionData);

  return (
    <>
      <ContributionHeader>
        <ContributionTitle>Contribution</ContributionTitle>
        <WalletWrap>
          <Wallet
            address={userData.walletAddress}
            username={userData.name}
            profileImage={userData.profileImage}
            expertise={userData.expertise}
          />
        </WalletWrap>
      </ContributionHeader>
      <ContributionContainer>
        <ContributionContent>
          <Swiper
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            loop={true}
          >
            {contributionData.map((slide) => (
              <SwiperSlide key={slide.cont_id}>
                <ContributionMain
                  logoUrl={slide.cont_logo}
                  title={slide.pjt_name}
                  kohortLabel={slide.cont_category}
                  totalAvg={'300'}
                  xp={slide.cont_xp}
                  startDate={slide.start_date}
                  endDate={slide.end_date}
                  progress={slide.cur_participant}
                  progressText={slide.progressText}
                  desc={slide.cont_desc}
                  imageUrl={slide.cont_banner}
                  id={slide.cont_id}
                  maxProgress={slide.max_participant}
                  type={slide.cont_type}
                  pjtId={slide.pjt_id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <Slider {...settings}>
          {contributionData.map((slide) => (
            <ContributionMain
              key={slide.cont_id}
              logoUrl={slide.cont_logo}
              title={slide.pjt_name}
              kohortLabel={slide.cont_category}
              totalAvg={'300'}
              xp={slide.cont_xp}
              startDate={slide.start_date}
              endDate={slide.end_date}
              progress={slide.cur_participant}
              progressText={slide.progressText}
              desc={slide.cont_desc}
              imageUrl={slide.cont_banner}
              id={slide.cont_id}
              maxProgress={slide.max_participant}
              type={slide.cont_type}
            />
          ))}
        </Slider> */}
          <ContributionTabs>
            <TabButton $active={activeTab === 'Ongoing'} onClick={() => setActiveTab('Ongoing')}>
              Ongoing
            </TabButton>
            <TabButton $active={activeTab === 'Finished'} onClick={() => setActiveTab('Finished')}>
              Finished
            </TabButton>
          </ContributionTabs>
          <CardGrid>
            {activeTab === 'Ongoing'
              ? ongoingCards.map((card) => (
                  <ContributionCard
                    key={card.cont_id}
                    id={card.cont_id} // id를 추가하여 넘김
                    title={card.pjt_name}
                    xp={card.cont_xp}
                    imageUrl={card.cont_banner}
                    logoUrl={card.cont_logo}
                    startDate={card.start_date}
                    endDate={card.end_date}
                    progress={card.cur_participant}
                    maxProgress={card.max_participant}
                    type={card.cont_type} // Kohort only 값을 넘김
                    desc={card.cont_desc}
                    pjtId={card.pjt_id}
                    kohortLabel={card.cont_category}
                  />
                ))
              : finishedCards.map((card) => (
                  <ContributionCard
                    key={card.cont_id}
                    id={card.cont_id} // id를 추가하여 넘김
                    title={card.pjt_name}
                    xp={card.cont_xp}
                    imageUrl={card.cont_banner}
                    logoUrl={card.cont_logo}
                    startDate={card.start_date}
                    endDate={card.end_date}
                    progress={card.cur_participant}
                    maxProgress={card.max_participant}
                    type={card.cont_type} // Kohort only 값을 넘김
                    desc={card.cont_desc}
                    pjtId={card.pjt_id}
                    kohortLabel={card.cont_category}
                  />
                ))}
          </CardGrid>
        </ContributionContent>
      </ContributionContainer>
    </>
  );
};

export default Contribution;
