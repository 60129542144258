import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// 스타일 정의
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  height: 150px;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);

  h3 {
    font-size: 16px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  p {
    font-size: 12px;
    text-align: left;
    line-height: 14px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
`;

interface MobileAlertModalProps {
  onClose: () => void;
}

const MobileAlertModal: React.FC<MobileAlertModalProps> = ({ onClose }) => (
  <ModalBackdrop>
    <ModalContent>
      {/* <CloseButton onClick={onClose}>X</CloseButton> */}
      <h3>Desktop Only</h3>
      <p>This site is currently optimized for desktop use. </p>
      <p>Please access from a desktop browser.</p>
    </ModalContent>
  </ModalBackdrop>
);

export default MobileAlertModal;
