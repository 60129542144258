import Twitter from './Twit.png';
import Telegram from './Telegram.png';
import Discord from './Discord.png';
import Link from './Link.png';

export const image = {
  Twitter,
  Telegram,
  Discord,
  Link,
};
