import styled from 'styled-components';

export const FeedBackWrapper = styled.div`
  /* align-items: center; */
  height: 100px;
  padding: 10px;
  background-color: white;

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #404040;
    margin-top: 10px;
  }

  @media (max-width: 1400px) {
    h3 {
      font-size: 18px;
    }
  }
`;

export const FeedBackIcon = styled.img`
  width: 50px;
  height: 50px;

  @media (max-width: 1400px) {
    width: 45px;
    height: 45px;
  }
`;

export const SubmitButton = styled.button`
  font-size: 18px;
  font-weight: 600;
  align-items: end;
  float: right;
  padding: 10px 32px;
  border-radius: 20px;
  background-color: #6a5feb;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #564dba;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
    padding: 10px 20px;
  }
`;
