export const PATH = {
  MAIN: '/',
  REGISTER: '/registration',
  MAINBOARD: '/main',
  DASHBOARD: '/main/dashboard',
  DEAL: '/main/deal',
  DEAL_DETAILS: '/main/deal/:dealId',
  CONTRIBUTION: '/main/contribution',
  CONTRIBUTION_DETAIL: '/main/contribution/contribution-detail/:contributionId',
  DISCOVER: '/main/discover',
  DISCOVER_DETAILS: '/main/discover/:projectId',
  DISCOVER_CALENDAR: '/main/discover/calendar',
  GOVERNANCE: '/main/governance',
  PROPOSAL: '/main/governance/proposal/:id',
  KOHORT: '/main/governance/kohort',
  ADMINLOGIN: '/kommdao-admin',
  ADMINMAINBOARD: '/admin-mainboard',
  USERAPPLICANTS: '/admin-mainboard/user/applicants',
  USERMEMBERS: '/admin-mainboard/user/members',
  USERCOMMITTES: '/admin-mainboard/user/committes',
  USERKOHORTS: '/admin-mainboard/user/kohorts',
  DEALROOM: '/admin-mainboard/deal/dealroom',
  DEALINTEREST: '/admin-mainboard/deal/dealinterest',
  ADMINDISCOVER: '/admin-mainboard/discover',
  PROFILE: '/main/dashboard/profile',
  PROFILE_UPDATE: '/main/dashboard/profile/profile-update',
  DEAL_INTEREST: '/main/deal/deal-details/:dealId/interest',
  CREATE_PROPOSAL: '/create-proposal',
  ADMINCONTRIBUTION: '/admin-mainboard/contribution',
  ADMINDEAL: '/admin-mainboard/deal/dealroom',
};
