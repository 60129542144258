import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  CardContainer,
  Header,
  HeaderItem,
  DataContainer,
  DataRow,
  DataItem,
  NoDataMessage,
} from './PortfolioCard.style';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { API_BASE_URL } from 'src/utils/utils';

interface PortfolioData {
  update_date: string;
  participation: string;
  deal_name: string;
  user_value: number;
  deal_status: string;
}

const PortfolioCard: React.FC = () => {
  const [portfolioData, setPortfolioData] = useState<PortfolioData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const userId = useSelector((state: RootState) => state.user.user_id); // 전역 상태에서 user_id를 가져옵니다.

  useEffect(() => {
    const fetchPortfolioData = async () => {
      if (userId) {
        try {
          // POST 요청을 통해 user_id를 백엔드로 전송
          const response = await axios.post(`${API_BASE_URL}/api/user/profile/get-user-interest`, { user_id: userId });
          console.log(response.data);
          setPortfolioData(response.data);
        } catch (error) {
          console.error('Error fetching portfolio data:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('User ID not found in Redux Store');
        setIsLoading(false);
      }
    };

    fetchPortfolioData();
  }, []);
  console.log(portfolioData);
  const formatCustomDate = (dateString: string) => {
    const date = new Date(dateString);

    // 한국 시간대에 맞춰 날짜와 시간을 24시간 형식으로 변환
    const formattedDate = date.toLocaleString('ko-KR', {
      timeZone: 'Asia/Seoul', // 한국 시간대 설정
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // 24시간 형식으로 설정
    });

    // `YYYY. MM. DD. HH:MM:SS` 형식으로 변환
    return formattedDate.replace(/(\d{4})\.(\d{2})\.(\d{2})\.\s(\d{2}):(\d{2}):(\d{2})/, '$1. $2. $3. $4:$5:$6');
  };

  return (
    <CardContainer>
      <Header>
        <HeaderItem>Date</HeaderItem>
        <HeaderItem>Participation</HeaderItem>
        <HeaderItem className="project">Project</HeaderItem>
        <HeaderItem className="amount">Amount</HeaderItem>
        <HeaderItem>Status</HeaderItem>
      </Header>
      <DataContainer>
        {isLoading ? (
          <NoDataMessage>Loading...</NoDataMessage>
        ) : portfolioData.length === 0 ? (
          <NoDataMessage>You are not currently participating in any projects.</NoDataMessage>
        ) : (
          portfolioData.map((item, index) => (
            <DataRow key={index}>
              <DataItem>{formatCustomDate(item.update_date)}</DataItem>
              <DataItem>{item.participation}</DataItem>
              <DataItem className="dealname">{item.deal_name}</DataItem>
              <DataItem className="uservalue">{item.user_value}</DataItem>
              <DataItem>{item.deal_status}</DataItem>
            </DataRow>
          ))
        )}
      </DataContainer>
    </CardContainer>
  );
};

export default PortfolioCard;
